import styled from '@emotion/styled';

export const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 3rem;
  background-color: var(--body-color);
`;

export const StyledSeparatorHalf = styled.div`
  height: 1px;
  width: 50%;
  margin: 1rem auto;
  background-color: var(--body-color);
`;

